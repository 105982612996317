.bgImage {
  width: calc(100% + 24px);
  height: calc(100% + 24px);
  margin: -12px;
  position: relative;
  z-index: 1;
  border-radius: 12px;

}

.noImage {
  width: inherit;
  position: relative;
  z-index: 1;
  top: 50%;
  left: 0;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.editButton {
  position: absolute !important;
  z-index: 2 !important;
  top: 0 !important;
  right: 4px !important;
  margin: 12px !important;
  height: 2.8571rem !important;
  min-width: 2.8571rem !important;
  padding: 8px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.container {
  padding: 12px;
  border-radius: 12px;
  width: 100%;
  height: auto;
  aspect-ratio: 16/5;
  background-color: var(--neutral-white-color);
}