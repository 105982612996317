.group {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.input {
  flex-grow: 1;
}

.actions {
  padding-top: 0.5rem;
  padding-left: 1.5rem;
  display: flex;
  align-items: center;
}
