.menu {
  min-width: fit-content !important;
}

.add {
  min-width: 200px !important;
}


.locationRow > td {
  border-top: solid 3px var(--ant-menu-submenu-item-background-color-hover) !important
}