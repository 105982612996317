$itemMargin: 10px;
$itemBorderSize: 2px;
$itemBorderColor: var(--neutral-stroke-color);
$itemBorder: $itemBorderSize solid $itemBorderColor;
$itemPadding: 3px;

.gallery {
  --image-box-item-height: 200px;
  --image-box-item-width: 200px;

  display: flex;
  flex-flow: row;
  justify-content: center;
  width: 100%;

  :global(.ant-row) {
    max-width: calc(var(--image-box-item-width) * 4 + #{$itemMargin} * 4);
    margin-left: calc(#{$itemMargin} / 2 * -1);
    margin-right: calc(#{$itemMargin} / 2 * -1);
    row-gap: $itemMargin;
  }

  :global(.ant-col) {
    padding-left: calc(#{$itemMargin} / 2);
    padding-right: calc(#{$itemMargin} / 2);
  }
}

.galleryItem {
  background-color: white;

  &:hover {
    .galleryItemActions {
      display: block;
    }
  }
}

.galleryItemActions {
  background-color: rgba(0, 0, 0, 0.7);
  transform: translateY(-100%);
  color: rgba(255, 255, 255, 0.8);
  padding: 1rem 2rem;
  display: none;
  text-align: center;
}

.skeleton {
  object-fit: cover;
  padding: $itemPadding;
  border: $itemBorder;

  &:global(.--loading) {
    padding: 70px;
  }
}

.image {
  object-fit: contain;
  padding: $itemPadding;
  border: $itemBorder;
  cursor: pointer;

  &:global(.--loading) {
    padding: 70px;
  }
}

.galleryItem,
.image,
.skeleton,
.upload {
  width: var(--image-box-item-width) !important;
  height: var(--image-box-item-height) !important;
}

.upload {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: $itemBorderSize dashed $itemBorderColor;
  cursor: pointer;
  color: var(--neutral-label-color);

  input {
    display: none;
  }
}

.uploadPlaceholder {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--neutral-disabled-color);
  width: 100%;
  height: 100%;
}

.uploadText {
  font-size: 1rem;
  text-align: center;
  font-weight: 500;

  :global(.plus) {
    font-size: 3rem;
    line-height: 4rem;
  }
}

.imageBox {
  --image-box-item-height: 200px;
  --image-box-item-width: 200px;

  &:global(.--compact) {
    --image-box-item-height: 100px;
    --image-box-item-width: 100px;
  }

  min-height: var(--image-box-item-height);
  margin: -10px;
  display: flex;
  flex-flow: row wrap;

  &:global(.--in-card) {
    @media screen and (max-width: 1681px) {
      --image-box-item-height: 140px;
      --image-box-item-width: 140px;
    }

    @media screen and (max-width: 1401px) {
      --image-box-item-height: 135px;
      --image-box-item-width: 135px;
    }

    @media screen and (max-width: 1281px) {
      --image-box-item-height: 140px;
      --image-box-item-width: 140px;
    }

    @media screen and (max-width: 1025px) {
      --image-box-item-height: 130px;
      --image-box-item-width: 130px;
    }

    @media screen and (max-width: 992px) {
      --image-box-item-height: 80px;
      --image-box-item-width: 80px;
    }
    @media screen and (max-width: 769px) {
      --image-box-item-height: 60px;
      --image-box-item-width: 60px;
    }
    @media screen and (max-width: 576px) {
      --image-box-item-height: 40px;
      --image-box-item-width: 40px;
    }
  }

}

.imageBoxItem {
  margin: 10px;
  position: relative;
}

.more {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  padding: $itemBorderSize + $itemPadding;
}

.moreContent {
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: calc(var(--image-box-item-height) / 4);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-property: background-color, color;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
    color: rgba(255, 255, 255, 0.7);
  }
}

:global(.ant-image-preview-root.image-box-preview-root .ant-image-preview-img) {
  background-color: white;
}
