.container {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.pickerInput {
  position: absolute;
  transform: translateX(-100000px);
}

.imagePicker {
  position: absolute;
  content: '';
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
  backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;
  border-radius: 50%;

  &:hover {
    opacity: 1;
  }
}

.imagePickerHolder {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: inherit;
  width: inherit;
}

.imagePickerInnerItem {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  backdrop-filter: grayscale(100%);

  &:hover {
    backdrop-filter: none;
  }
}

.cropperContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.cropperContent {
  position: relative;
}

.cropperActions {
  position: absolute;
  top: 0;
  right: 10%;

  & > :global(.anticon) {
    padding: 12px;
  }

  & > :global(.anticon) > svg {
    color: white;
    cursor: pointer;
    font-size: 18px;
  }

  & > :global(.anticon) + :global(.anticon) {
    margin-left: 12px;
  }
}

:global(.ReactCrop).cropperCrop {
  display: block;
  margin: auto;
  max-width: 80%;
  max-height: 80vh;

  :global(.ReactCrop__image) {
    max-height: 80vh;
  }
}
