.status {
  align-items: center;
  background: #1cb494;
  color: white !important;
  padding: 1px 10px 1px 10px;
  border-radius: 100px;
}

.collapse {
  :global(.ant-collapse-header-text) {
    width: 100%;
  }
}

.filter {
  max-width: 200px !important;
}
