.filter {
  padding-top: 10px;

  :global(.ant-typography) {
    color: var(--neutral-label-color) !important;
  }
}

.radioButton {
  :global(.ant-radio-button-wrapper.ant-radio-button-wrapper-in-form-item) {
    color: var(--ant-default-color);
    border-color: var(--ant-default-color);
    line-height: 36px;
    min-width: 280px;
    min-height: 36px;
    text-align: center;
    font-weight: 600;

    &::before {
      background-color: var(--ant-default-color);
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }
    &:first-child {
      border-radius: 4px 0 0 4px;
    }
  }
}

.additionalOptions {
  margin-top: 60px;
  :global(.ant-form-item label) {
    color: rgba(0, 0, 0, 0.85);
  }
}
