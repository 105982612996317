.iconButton {
  width: inherit;
  aspect-ratio: 1/1;
  height: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 12px;
  font-size: var(--font-size);
  cursor: pointer;
  background-color: rgba(255,255,255,0.4);

  &:hover {
    transition: 300ms ease;
    background-color: rgba(255,255,255,0.6);
  }

  &:active {
    background-color: rgba(255,255,255,0.2);
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--neutral-white-color);
  font-size: 3rem;
  height: 70%;
  aspect-ratio: 1/1;
  margin: auto;

  @media screen and (max-width: 900px) {
    font-size: 2rem;
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  aspect-ratio: 1/1;
  margin: auto;
  border-radius: 50%;

  &:global(.navy) {
    background-color: rgb(49, 96, 144);
  }

  &:global(.cyan) {
    background-color: rgb(102, 185, 167);
  }

  &:global(.blue) {
    background-color: rgb(58, 119, 181);
  }

  &:global(.emerald) {
    background-color: rgb(89, 159, 176);
  }
}

.title {
  display: block;
  text-align: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media screen and (max-width: 976px) {
    font-size: 0.8rem;
  }
}