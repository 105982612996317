$maxWidth: 1000px;

.btn {
  & + .list {
    padding-top: 1rem;
  }
}

.list {
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
  max-width: $maxWidth;

  li {
    color: var(--primary-dark-blue-color);
    padding: 2px;
    display: flex;
    justify-content: space-between;

    & + li {
      margin-top: 12px;
    }

    &:hover {
      background-color: var(--bluish-menu-active-background-color);
      color: var(--primary-classic-blue-color);

      .delete {
        opacity: 1;
      }
    }
  }
}

.link {
  span {
    text-decoration: underline !important;
  }

  &:hover {
    color: var(--neutral-dark-color) !important;
  }
}

.delete {
  opacity: 0;
  width: 25px !important;
  margin-left: 15px;
  color: var(--neutral-grey-navy-light-color) !important;
}

.title,
.title button {
  display: inline-flex;
  align-items: center;
}

.fileName {
  display: inline-block;
  max-width: calc(#{$maxWidth} - 4px - 25px - 40px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
