.nav {
  :global(.ant-tree-title) {
    padding-right: 24px;

    :global(.anticon) {
      padding-right: 4px;
      font-size: 0.7rem;
    }
  }

  :global(.ant-tree-checkbox) {
    align-self: center;
    margin: 0 20px 0 0;
  }
}

.search {
  width: 50% !important;
}
