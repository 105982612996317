.actualCost {
  :global(.form-group) {
    display: inline-block;
    margin-bottom: 0;
    max-width: 120px;
  }

  :global(.ant-btn) {
    margin-top: 9px;
  }
}

.summaryRow {
  font-weight: 600;

  td:not(:first-child) {
    background-color: var(--antd-table-head-background-color);
  }
}
