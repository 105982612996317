.start {
  width: 200px !important;
}

.project {
  :global(.ant-tag) {
    border-radius: 8 !important;
    border: 1px solid !important;
    line-height: 2.143rem;
    font-size: 1rem;
    min-width: 180px !important;
    text-align: center;
  }
  margin-bottom: 1rem;
  padding-right: 2rem;
}
