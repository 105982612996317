@import '../../../assets/styles/typography.scss';

.noSelection {
  @extend .text-error;
}

.label {
  color: #1e82a1 !important;
}

.collapse {
  :global(.ant-collapse-header) {
    background-color: rgba(#1e82a1, 0.1);
    border: var(--header-border, 1px solid #1e82a1);
  }
  :global(.ant-collapse-conten) {
    border: 0 !important;
  }
  :global(.ant-collapse-header-text) {
    width: 100%;
  }
  :global(.ant-collapse-expand-icon) {
    color: #1e82a1;
  }
  margin-top: 2px !important;
}

.name {
  color: #1e82a1 !important;
}

.add {
  background-color: transparent !important;
  color: #1e82a1 !important;
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
}

.add :hover {
  color: var(--primary-classic-blue-color);
}

.select {
  width: 150px;
}

.step {
  :global(.ant-tag) {
    padding: 8px 16px 8px 16px;
    border-radius: 20px !important;
  }
  :global(.ant-typography) {
    font-size: var(--font-size);
    font-weight: var(--h4-sub-font-weight);
    color: var(--primary-dark-blue-color) !important;
  }
}
