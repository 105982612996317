.personalizationStepsBtn {
  width: 100%;
  :global(.ant-select-selector) {
    min-height: 40px !important;
    max-height: 40px !important;
    min-width: 300px;
  }
  :global(.ant-select-selection--item) {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
}

.imageName {
  width: 200px;
}

.personalizationHistory {
  width: 300px;
}
