.body {
  width: 100%;
  flex: 0 0 none;
  display: block;

  &:global(.--compact) {
    width: 50%;
  }
}

.content {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 30px;

  &:global(.min-auto) {
    min-height: 20rem;
  }
}

.nav {
  display: block;
  flex: 0 0 none;
  width: 25%;
  padding-right: 32px;

  li {
    display: flex;

    .TabItemIcon {
      margin-left: auto;
      align-self: center;
    }
  }
}

.navTitle {
  margin-bottom: 1.42857rem !important;
}

.nav + .body {
  width: 75%;
}

.nav + .body:global(.--compact) {
  width: 50%;
}
