.tab {
  :global(.ant-radio-button-wrapper) {
    color: var(--neutral-label-color);
    border-color: var(--neutral-grey-navy-light-color);
    line-height: 40px;
    min-width: 150px;
    min-height: 40px;
    text-align: center;
    font-weight: 600;

    &:hover {
      color: var(--addition-dark-green-color) !important;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }
    &:first-child {
      border-radius: 4px 0 0 4px;
    }
  }

  :global(.ant-radio-button-wrapper-checked) {
    border-color: var(--addition-classic-green-color) !important;
    color: var(--addition-dark-green-color) !important;
    background-color: var(--addition-greenish-color) !important;

    &::before {
      background-color: var(--neutral-grey-navy-light-color) !important;
    }
  }
}
