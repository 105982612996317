.scaledContainer {
  margin-top: 30px;

  & table td,
  & table th {
    padding: calc(16px * var(--zoom-index)) !important;
  }

  & table td > *,
  & table th > * {
    transform: scale(var(--zoom-index));
  }

  :global(.title) {
    font-size: calc(var(--font-size) * var(--zoom-index)) !important;
  }

  :global(.ant-table-measure-row) {
    & td {
      padding: 0px !important;
    }
  }
}

.zoomBtn {
  :global(.anticon) {
    color: var(--neutral-grey-navy-light-color) !important;
    font-size: 1.5rem;
    :hover {
      color: var(--addition-classic-green-color);
    }
  }
}
