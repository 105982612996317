.homeInput {
  min-width: 250px;
  max-width: 300px;
}

.card {
  :global(.ant-card-head-wrapper) {
    display: inline-flex;
    gap: 10px;
  }
  :global(.ant-typography.ant-typography-secondary) {
    font-weight: var(--font-weight);
    font-size: var(--font-size);
  }
}

.card {
  :global(.ant-card-head-wrapper) {
    display: inline-flex;
    gap: 10px;
  }
  :global(.ant-typography.ant-typography-secondary) {
    font-weight: var(--font-weight);
    font-size: var(--font-size);
  }
}
