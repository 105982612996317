.editAction {
  color: var(--neutral-grey-navy-color) !important;
  :hover {
    color: var(--addition-classic-green-color);
  }
}

.deleteAction {
  color: var(--neutral-grey-navy-color) !important;
}

.secondary {
  border-color: var(--bluish-02-color) !important;
  color: var(--primary-dark-blue-color) !important;
}
