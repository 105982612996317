.language {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

.tooltip :global(.ant-tooltip-inner) {
  max-height: 315px;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 200px;
  width: 350px;
  max-width: 350px;
  display: -webkit-box;
  line-clamp: 14;
  -webkit-line-clamp: 14;
  -webkit-box-orient: vertical;
}
