$actionsHeight: 42px;

body:global(.overlay-visible) {
  .container {
    overflow-y: auto;
  }
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 100;
  overflow-y: auto;
}

.content {
  width: 100%;
  min-height: calc(100vh - #{$actionsHeight});
  margin-top: $actionsHeight;
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.actions {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  text-align: right;
  height: $actionsHeight;
  background-color: rgba(0, 0, 0, 0.1);

  & > span > :global(.anticon) {
    padding: 12px;
  }

  & > span > :global(.anticon) > svg {
    color: white;
    cursor: pointer;
    font-size: 18px;
  }

  & > span > :global(.anticon) + :global(.anticon) {
    margin-left: 12px;
  }
}

body:global(.overlay-visible) {
  overflow: hidden;
}
