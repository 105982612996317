.fullScreen {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: white;
  z-index: 999;
  padding: 2rem;
  overflow-y: auto;

  :global(.ant-table-sticky-holder) {
    top: -28px !important;
  }
}

.fullScreenBtn {
  :global(.anticon) {
    color: var(--neutral-grey-navy-light-color) !important;
    font-size: 1.5rem;
    :hover {
      color: var(--addition-classic-green-color);
    }
  }
}