.subtitle {
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  align-self: center;
}

.title {
  max-width: 350px;
  text-overflow: ellipsis;
  overflow: hidden;
  align-self: center;
}

.actions {
  display: flex;
}

@media screen and (max-width: 1023px) {
  .actions {
    flex-direction: column;
  }
  .title,
  .subtitle {
    max-width: 25vw;
  }
}

@media screen and (min-width: 1024px) and (max-device-width: 1439px) {
  .title,
  .subtitle {
    max-width: 13vw;
  }
}
