$headerHeight: 57px;
$bottomSpaceAreaHeight: 350px;

.layout:global(.ant-layout) {
  --header-height: #{$headerHeight};

  font-size: var(--font-size);

  background: url('../../assets/images/page-background-image.svg'),
    var(--page-background);
  background-repeat: no-repeat;
  background-position: left bottom;
}

.nav {
  justify-content: end;
}

.main {
  flex: 1 0 auto !important;
  margin-top: $headerHeight;
  margin-bottom: $bottomSpaceAreaHeight;

  &::before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 500px;
    background: linear-gradient(
      var(--page-background-color-start) 40%,
      transparent
    );
  }
}

.header:global(.ant-layout-header) {
  background: white !important;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;
  height: $headerHeight;
  line-height: $headerHeight;
  box-shadow: 0px 2px 6px rgba(20, 96, 148, 0.1);

  :global(.ant-menu) {
    height: $headerHeight;

    :global(.user-actions) :global(.ant-menu-submenu-title) {
      :global(.ant-menu-title-content),
      :global(.ant-menu-submenu-arrow) {
        display: none;
      }
    }
  }

  :global(.ant-col) {
    height: $headerHeight;
  }

  svg {
    font-size: 1.71rem;
    vertical-align: middle;
  }
}

.footer {
  &:global(.ant-layout-footer) {
    background-color: transparent;
    border-top: none;
    color: var(--text-color-help);
    font-size: 0.75em;
  }
}

.brand {
  height: 100%;
  display: flex;
  align-items: center;

  svg {
    font-size: 54px !important;
  }
}

.searchByNumber {
  width: 200px;
  padding-top: 16px !important;

  :global(.ant-select-selector) {
    display: flex;
    align-items: center;
    height: 30px !important;
    border: none !important;
    outline: none !important;
  }

  :global(.ant-select-selection-search-input) {
    padding-top: 0px !important;
  }

  svg {
    font-size: 16px !important;
    margin-bottom: 8px;
    margin-right: 4px;
  }
}
