.panel {
  @media screen and (max-width: 1281px) {
    --font-size: 9px !important;
  }
}

.column {
  padding: 4px 7px !important;
}

.priceInput:global(.ant-input-number-affix-wrapper) {
  :global(.ant-input-number-prefix) {
    @media screen and (max-width: 1281px) {
      font-size: 9px !important;
    }
  }

  :global(.ant-input-number) {
    :global(.ant-input-number-input-wrap) {
      input {
        @media screen and (max-width: 1281px) {
          font-size: 9px !important;
        }
      }
    }
  }
}

.itemType:global(.ant-select:not(.ant-pagination-options-size-changer)) {
  @media screen and (max-width: 1281px) {
    font-size: 9px !important;
  }
}
