.nav {
  :global(.ant-tree-iconEle) {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  :global(.ant-tree-switcher .ant-tree-switcher-icon) {
    margin-right: 12px !important;
  }

  :global(.item-child .anticon.anticon-plus) {
    margin-right: 12px;
  }

  :global(span.ant-tree-title) {
    margin-left: 12px !important;
    padding-right: 30px;
  }

  :global(.item-child .ant-tree-title .ant-btn) {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--neutral-grey-navy-color);
  }
}

.addBtn {
  width: 140px;
}

.navClass {
  min-width: 25%;
  width: fit-content !important;
  max-width: 40% !important;
}
