.summaryInfo {
  background-color: var(--neutral-disabled-color);
  padding: 16px 0px 16px 12px;

  .subtotalTitle {
    font-weight: var(--h4-sub-font-weight) !important;
    line-height: 24px;
    color: var(--neutral-text-color);
    margin-bottom: 0px !important;
  }
  .subtotalValue {
    text-align: end;
    font-weight: var(--h4-sub-font-weight) !important;
    line-height: 24px;
    color: var(--neutral-dark-color);
    margin-bottom: 0px !important;
  }
}

.totalInfo {
  background-color: var(--addition-greenish-color);
  padding: 25px 0px 25px 12px;

  .totalTitle {
    font-weight: var(--h4-sub-font-weight) !important;
    line-height: 30px;
    color: var(--primary-navy-blue-color);
    margin-bottom: 0px !important;
  }

  .totalValue {
    text-align: end;
    font-weight: var(--h4-sub-font-weight) !important;
    line-height: 30px;
    color: var(--primary-navy-blue-color);
    margin-bottom: 0px !important;
  }
}

.paymentCol {
  display: inline-flex !important;
}

.specialRequestIcon {
  color: var(--addition-gold-color) !important;
}

.title {
  color: var(--neutral-dark-color) !important;
}

.label {
  color: var(--neutral-label-color) !important;
}

.subtitle {
  :global(.ant-tag) {
    padding: 8px 16px 8px 16px;
    border-radius: 20px !important;
  }
  :global(.ant-typography) {
    font-size: var(--font-size);
    font-weight: var(--h4-sub-font-weight);
    color: var(--primary-dark-blue-color) !important;
  }
}

.priceColumnsCheckboxGroup {
  display: flex;
  justify-content: flex-end;
}

.description {
  white-space: pre-line !important;
  max-width: 300px;
}
