.linkPanel {
  color: var(--ant-primary-color);
  margin-top: 5px;

  :global(.ant-list) {
    color: inherit;
  }

  .linkRow {
    &:hover {
      background-color: var(--bluish-menu-active-background-color);
      color: var(--primary-classic-blue-color);

      .delete {
        opacity: 1;
      }
    }

    .delete {
      opacity: 0;
      margin-left: auto;
      padding-left: 10px;
      color: var(--neutral-grey-navy-light-color) !important;
    }

    .label {
      color: inherit;
      font-size: 13px;
      vertical-align: text-top;
      text-overflow: ellipsis;
      max-width: 230px;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
    }
  }
}

.linkModal {
  :global(.ant-modal-header) {
    padding-top: 32px !important;
    border: none;

    :global(.ant-modal-title) {
      font-size: var(--h2-font-size) !important;
      line-height: 30px;
      color: var(--primary-navy-blue-color);
    }
  }

  :global(.ant-modal-footer) {
    padding-right: 32px !important;
  }
}
