.columnsMenu {
  margin-bottom: 1rem;

  :global(.ant-dropdown-menu-item-group-list) {
    max-height: 500px;
    overflow: auto;
    margin-right: 0;
    margin-left: 0;
  }

  :global(.ant-checkbox-wrapper) {
    width: 100%;
  }
}
