.label {
  padding-right: 0.5rem;
  color: var(--neutral-label-color);

  &::after {
    content: ':';
  }
}

.addToProject {
  padding-right: 0.5rem;
  color: var(--ant-primary-color) !important;
}

.icon {
  color: var(--neutral-label-color);
}
