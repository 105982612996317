.nameInput {
  min-width: 430px !important;
}

.statusText {
  font-size: 0.9286rem !important;
  letter-spacing: 0.3px;
}

.editAction {
  :global(.anticon) {
    color: var(--neutral-grey-navy-light-color) !important;
    font-size: 1.429rem;
  }
}
