.listItemSelectable {
  .listItemRadio {
    display: inline-block;
    width: 40px;
  }

  .listItemContent {
    display: inline-block;
    width: calc(100% - 40px);
  }
}
