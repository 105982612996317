.cardHeader {
  :global(div.ant-collapse-header) {
    background-color: var(--neutral-disabled-color);
    margin-bottom: 4px;
  }
}

.cardBody {
  :global(h2.ant-typography) {
    color: var(--neutral-dark-color);
    font-weight: 600;
  }
  :global(.ant-typography) {
    color: var(--neutral-dark-color);
  }
  :global(.ant-tag.ant-tag-blue) {
    color: var(--neutral-dark-color);
    border-radius: 0 !important;
    border: 1px solid #a0cfed !important;
    line-height: 2.143rem;
    font-size: 1rem;
  }
  :global(.anticon) {
    color: var(--neutral-grey-navy-light-color);
  }
  :global(.ant-space.ant-space-vertical) {
    gap: 0 !important;
    font-size: 0.928rem;
    line-height: 1.214rem;
  }
  :global(.ant-space-item) {
    margin-top: -3px;
  }
}

.description {
  color: var(--neutral-dark-grey-color) !important;
  font-size: 1.0715rem;
  line-height: 1.86rem;
  margin-bottom: 12px;
  white-space: pre-line !important;
}

.notes {
  color: var(--neutral-label-color) !important;
  font-size: 1.0715rem;
  font-style: italic;
  margin-bottom: 12px;
  white-space: pre-line !important;
}

.label {
  color: var(--neutral-label-color) !important;
}

.comments {
  color: var(--neutral-dark-grey-color) !important;
  font-size: 1.0715rem;
  margin-bottom: 12px;
}

.step {
  :global(.ant-tag) {
    padding: 8px 16px 8px 16px;
    border-radius: 20px !important;
  }
  :global(.ant-typography) {
    font-size: var(--font-size);
    font-weight: var(--h4-sub-font-weight);
    color: var(--primary-dark-blue-color) !important;
  }
}

.collapse {
  margin-top: -68px;
}
