.container {
  width: 450px;
}

.adaptiveContainer {
  width: auto;
  height: inherit;
}

.list {
  max-height: 500px;
  overflow-y: auto;
}

.adaptiveList {
  height: 100%;
  max-height: 18.5vw;
  overflow-y: auto;

  @media screen and (max-width: 1700px) {
    max-height: 20vw;
  }

  @media screen and (max-width: 1281px) {
    max-height: 21vw;
  }

  @media screen and (max-width: 985px) {
    max-height: 33vw;
  }

  @media screen and (max-width: 768px) {
    max-height: 40vw;
  }
}

.buttons {
  display: flex;
  justify-content: space-around;
  font-size: small;
  padding: 2px;
}
