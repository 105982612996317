.sider {
  height: 50% !important;
  width: 5px !important;
  min-width: 5px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  text-align: center !important;
  background-color: #e4f4fc !important;
  padding: 0 !important;
  border: 0 !important;
  transition: background-color 0.3s;
  font-size: 24;
  margin: auto;
}

.disable {
  cursor: not-allowed;
  pointer-events: none;
  color: #e5e8ea !important;
}

.content-end {
  justify-content: end !important;
}

.content-start {
  justify-content: start !important;
}

.icon {
  font-size: 24px !important;
}

.sider:hover {
  background-color: #d4e4f4 !important; /* Change to the desired background color */
}

.card {
  :global(.ant-typography.ant-typography-secondary) {
    font-weight: var(--font-weight);
    font-size: var(--font-size);
  }
}
