.container {
  width: 100%;
  padding: 1.5rem;
}

.singleContainer {
  width: 100%;
  padding-bottom: 1.5rem;
}

.imageView {
  display: flex;
  flex: 1;
}

.actions {
  margin: -1.5rem -1.5rem 0 -1.5rem;
  text-align: right;
  padding: 1rem 1.5rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;

  :global(.ant-btn-icon-only .anticon) {
    font-size: 1.5rem;
    color: var(--primary-classic-blue-color);
  }
}

.lock {
  margin-right: 15px !important;
}

.clickable {
  cursor: pointer;
}

.actions + .view {
  margin-top: 3rem;
}

.status {
  margin-right: auto;
}

.view {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
}

.singleView {
  position: relative;
  background-repeat: no-repeat;
  background-size: 200% 200%;
  width: 100%;
  border: 2px solid black;
  height: 200px;
}

.viewContainer {
  margin: auto;
}

.previewView.viewContainer {
  max-width: 80vw;
  max-height: 80vh;
}

.marker {
  position: absolute;
  background-color: var(--primary-classic-blue-color);
  font-size: 0.8vw;
  height: 1.6vw;
  line-height: 1.6vw;
  width: 1.6vw;
  text-align: center;
  border-radius: 50%;
  color: white;
  font-weight: 500;
  margin-top: -0.8vw;
  margin-left: -0.8vw;
  transform: translateY(calc(-50% - 9px));
  display: block;

  &::before {
    content: ' ';
    position: absolute;
    top: calc(1.6vw - 1px);
    height: 0.8vw;
    width: 0.2vw;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--primary-classic-blue-color);
  }

  &:global(.--dragging) {
    opacity: 0.3;
  }

  &:global(.--draggable) {
    cursor: move;
  }
}

.iconMarker {
  @extend .marker;

  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    font-size: 2rem;
    color: var(--addition-gold-color);
  }

  .text {
    font-size: 0.8rem;
    position: absolute;
    color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &::before {
    top: calc(2rem - 7px);
    height: 16px;
    width: 3px;
    background-color: var(--addition-gold-color);
  }
}

.markerActionsWrap {
  position: absolute;
  top: -1rem;
  left: -1rem;
  width: 4rem;
  height: 4rem;

  &:hover {
    .markerActions {
      display: block;
    }
  }
}

.markerActions {
  display: none;
  position: absolute;
  transform: translateX(2.5rem) translateY(-35%);
  color: var(--font-color) !important;
  background-color: white;
  padding: 0 5px;
  border: 1px solid var(--neutral-stroke-color);
  border-radius: 2px;
}
